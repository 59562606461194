import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import matter from 'gray-matter';
import ReactMarkdown from 'react-markdown';

function BlogPost() {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [meta, setMeta] = useState({});

  useEffect(() => {
    async function fetchPost() {
      const res = await fetch(`/blog/${slug}.md`);
      const text = await res.text();
      const { content, data } = matter(text);
      setContent(content);
      setMeta(data);
    }

    fetchPost();
  }, [slug]);

  useEffect(() => {
    document.title = `Blog - ${meta.title}`;
  }, [meta]);

  if (!meta.title) {
    return (
      <div className="min-h-screen bg-white font-inter flex items-center justify-center">
        <div className="text-xl text-gray-500 animate-pulse">
          loading...
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white font-inter">
      {/* Header with gradient */}
      <div className="bg-gradient-to-b from-gray-50 to-white pt-12 pb-8 md:pt-16 md:pb-12">
        <div className="max-w-3xl mx-auto px-6">
          {/* Back button */}
          <Link 
            to="/blog" 
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-12 group"
          >
            <span className="transform group-hover:-translate-x-1 transition-transform duration-200">←</span>
            <span className="ml-2">back to blog</span>
          </Link>

          {/* Post header */}
          <div className="space-y-4">
            <time className="text-sm text-gray-500">
              {meta.date}
            </time>
            
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 tracking-tight">
              {meta.title}
            </h1>
            
            {meta.description && (
              <p className="text-xl text-gray-600 mt-4">
                {meta.description}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-3xl mx-auto px-6 py-12">
        <ReactMarkdown 
          className="prose prose-lg prose-gray max-w-none
            prose-headings:font-medium prose-headings:tracking-tight
            prose-h2:text-2xl prose-h2:mt-12 prose-h2:mb-6
            prose-p:text-gray-600 prose-p:leading-relaxed
            prose-a:text-gray-900 prose-a:border-b prose-a:border-gray-200 
            prose-a:no-underline hover:prose-a:border-gray-500 
            prose-a:transition-colors
            prose-code:text-gray-800 prose-code:bg-gray-50 
            prose-code:px-1 prose-code:py-0.5 prose-code:rounded
            prose-pre:bg-gray-50 prose-pre:border prose-pre:border-gray-200
            prose-img:rounded-lg prose-img:shadow-sm
            prose-blockquote:border-l-2 prose-blockquote:border-gray-200
            prose-blockquote:text-gray-600 prose-blockquote:italic"
        >
          {content}
        </ReactMarkdown>
        <div className="mt-16">
            <h2 className="text-2xl text-gray-800 font-medium mb-4">Get in touch</h2>
            <div className="flex flex-wrap gap-6 text-lg">
              <a href="https://twitter.com/jog_soham" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">Twitter</a>
              <a href="https://github.com/sohamjog/" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">GitHub</a>
              <a href="https://www.linkedin.com/in/soham-jog-ba4b62237/" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">LinkedIn</a>
              <a href="mailto:jogsoham2003@gmail.com" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">Email</a>
            </div>
          </div>
      </div>
      
    </div>
  );
}

export default BlogPost;