import React from 'react';
import img2 from './another_pic.jpeg';

function About() {
  return (
    <div className="min-h-screen bg-white font-inter p-4 md:p-8 max-w-6xl mx-auto">
      <div className="flex flex-col md:flex-row gap-8 md:gap-16 items-start">
        {/* Large Image */}
        <div className="w-full md:w-1/3 md:sticky md:top-8">
          <img
            src={img2}
            alt="Soham"
            className="w-full h-auto rounded-lg object-cover"
          />
        </div>

        {/* Content */}
        <div className="flex-1">
          <h1 className="text-4xl md:text-5xl text-gray-900 font-bold mb-12">
            Hi, I'm Soham
          </h1>

          <div className="space-y-6">
            <h2 className="text-2xl text-gray-800 font-medium">About me</h2>
            <p className="text-lg text-gray-700">
              I'm currently studying Computer Science at Purdue University. Lately, my interests have shifted towards blockchain development, algorithm design, and distributed systems. I'm also a big fan of Rust and Solidity.
            </p>

            <p className="text-lg text-gray-700">
              This past summer, I had the epic opportunity to intern at Meta, where I worked on the Developer Integrity team which helps protect the Meta API. I learned a lot about the inner workings of a large tech company. I also got to work with some amazing people, which was a huge plus.
            </p>

            <p className="text-lg text-gray-700">
              Now I'm back at school, leading <a className="border-b border-dotted hover:bg-blue-100 border-gray-300 hover:border-gray-600 transition-colors" href="https://www.boilerblockchain.org/" target="_blank" rel="noopener noreferrer">Purdue's Blockchain Club,</a> where I also teach a class on web3 development. On top of that, a few friends and I are building a low-latency course-search tool that we're pretty excited about—check it out <a className="border-b border-dotted hover:bg-blue-100 border-gray-300 hover:border-gray-600 transition-colors" href="https://www.boilerclasses.com/" target="_blank" rel="noopener noreferrer">here</a>.
            </p>

            <p className="text-lg text-gray-700">
            I'm also digging into research, currently working under Dr. Aniket Kate on topics in Secure Distributed Computing and Multi-Party Computation. This involves building Rust implementations of modern distributed computing protocols, alongside developing a Rust library for Reed-Solomon Error Correction Codes, which you can check out <a className="border-b border-dotted hover:bg-blue-100 border-gray-300 hover:border-gray-600 transition-colors" href="https://github.com/SohamJog/reed_solomon_rs" target="_blank" rel="noopener noreferrer">here</a>.
            </p>

            <p className="text-lg text-gray-700">
              Some other things I've built are <a className="border-b border-dotted hover:bg-blue-100 border-gray-300 hover:border-gray-600 transition-colors" href="https://ethglobal.com/showcase/soho-xo1fi" target="_blank" rel="noopener noreferrer">Soho</a>, <a className="border-b border-dotted hover:bg-blue-100 border-gray-300 hover:border-gray-600 transition-colors" href="https://github.com/visvshah/Pulse" target="_blank" rel="noopener noreferrer">Pulse</a>, <a className="border-b border-dotted hover:bg-blue-100 border-gray-300 hover:border-gray-600 transition-colors" href="https://ethglobal.com/showcase/daolingo-fd6uw" target="_blank" rel="noopener noreferrer">DaoLingo</a>, and <a className="border-b border-dotted hover:bg-blue-100 border-gray-300 hover:border-gray-600 transition-colors" href="https://www.roamer.dev/" target="_blank" rel="noopener noreferrer">Roamer</a>.
            </p>
          </div>

          <div className="mt-16">
            <h2 className="text-2xl text-gray-800 font-medium mb-4">Get in touch</h2>
            <div className="flex flex-wrap gap-6 text-lg">
              <a href="https://twitter.com/jog_soham" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">Twitter</a>
              <a href="https://github.com/sohamjog/" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">GitHub</a>
              <a href="https://www.linkedin.com/in/soham-jog-ba4b62237/" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">LinkedIn</a>
              <a href="mailto:jogsoham2003@gmail.com" target="_blank" rel="noopener noreferrer" className="border-dotted hover:bg-blue-100 text-gray-700 border-b border-gray-300 hover:border-gray-600 transition-colors">Email</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;