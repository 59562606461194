import React from 'react';
import About from './About';
import Blog from './Blog';
import BlogPost from './BlogPost';
import { Routes, BrowserRouter, Route, Link, useLocation } from "react-router-dom";
import { Buffer } from 'buffer';
window.Buffer = Buffer;

function NavBar() {
  const location = useLocation();
  
  return (
    <nav className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm border-b border-gray-100 z-50">
      <div className="max-w-6xl mx-auto px-6">
        <div className="flex h-16 items-center justify-between">
          <Link 
            to="/" 
            className="text-lg font-medium text-gray-900 hover:text-gray-600 transition-colors"
          >
            soham jog
          </Link>
          
          <div className="flex gap-8">
            <Link 
              to="/" 
              className={`text-sm ${
                location.pathname === '/' 
                  ? 'text-gray-900' 
                  : 'text-gray-500 hover:text-gray-900'
              } transition-colors`}
            >
              about
            </Link>
            <Link 
              to="/blog" 
              className={`text-sm ${
                location.pathname.includes('/blog') 
                  ? 'text-gray-900' 
                  : 'text-gray-500 hover:text-gray-900'
              } transition-colors`}
            >
              blog
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

function App() {
  return (
    <BrowserRouter>
      <div className="pt-16"> 
        <NavBar />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;